<script setup>
import {nextTick, ref, watch, computed} from 'vue'
import debounce from 'debounce'
import api from '@helpers/api.js'
import {relativeUrl} from '@helpers/utils.js'
import moment from 'moment'
import ListingTags from '@components/ListingTags.vue'
import { $isSearchOpen } from '../store'
import { useStore } from '@nanostores/vue'

defineProps({
  bestSellers: {
    type: Array,
    default: [],
  },
  latestPosts: {
    type: Array,
    default: [],
  },
})

const searchPageActive = computed({
  get() {
    return useStore($isSearchOpen).value
  },
  set(val) {
    $isSearchOpen.set(val)
  },
})

const loading = ref(false)
const query = ref('')
const navBarSearchInput = ref(null)

const searchedListings = ref([])
const searchedCollections = ref([])
const searchedPosts = ref([])

const topSearches = [
  'Agave',
  'Delta-9',
  'Lemonade',
  'Delta-8',
]

watch(query, () => {
  loading.value = true
  search()
})

watch(searchPageActive, value => {
  if (value) {
    nextTick(() => navBarSearchInput.value.focus())
  }
})

const search = debounce(async function () {
  if (!query.value) {
    searchedListings.value = []
    searchedCollections.value = []
    searchedPosts.value = []
    loading.value = false
    return
  }

  const data = await api.post('/search', { query: query.value }).then(({ data }) => data)
  searchedListings.value = data.listings
  searchedCollections.value = data.collections
  searchedPosts.value = data.posts
  loading.value = false
}, 750)
</script>

<template>
  <div class="search">
    <div class="search__nav-bar">
      <input type="text" name="query" placeholder="Search" @click="searchPageActive = true">
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none" @click="searchPageActive = true">
        <g clip-path="url(#clip0_6173_39551)">
          <path d="M25.7546 24.1261C25.6013 23.9039 25.4083 23.708 25.2179 23.5175C23.1002 21.3978 20.9851 19.2834 18.8488 17.1478C22.4551 12.4983 21.5297 6.0148 17.4528 2.51108C13.5742 -0.815331 7.49593 -0.855026 3.72046 2.49256C-0.361706 6.11536 -1.19982 12.014 1.77984 16.4148C3.21547 18.5345 5.15873 20.0217 7.6387 20.6965C11.089 21.6333 14.2722 21.0247 17.2519 18.7647C17.4184 18.9923 17.5427 19.2146 17.7172 19.3945C19.6684 21.3581 21.6222 23.3164 23.5813 25.272C23.7558 25.4493 23.933 25.6292 24.1392 25.7695C24.6653 26.1268 25.284 26.0738 25.6753 25.6266C26.0878 25.1556 26.1036 24.6395 25.7573 24.1288L25.7546 24.1261ZM10.5972 18.7118C6.04972 18.7197 2.41966 15.1207 2.40909 10.5929C2.39587 6.02803 6.0365 2.34966 10.5655 2.34701C15.0495 2.34436 18.7377 6.03332 18.7483 10.532C18.7563 15.0387 15.1024 18.7091 10.5972 18.7144V18.7118Z" fill="#183D4F"/>
        </g>
        <defs>
          <clipPath id="clip0_6173_39551">
            <rect width="26" height="26" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </div>

    <div :class="['search__page', {'search__page--active': searchPageActive}]">
      <div class="search__bar">
        <svg class="search__magnifying" xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 43 43" fill="none">
          <g clip-path="url(#clip0_12851_57977)">
            <path d="M42.5922 39.9006C42.3386 39.533 42.0194 39.2091 41.7046 38.894C38.2022 35.3883 34.7041 31.8914 31.171 28.3595C37.1353 20.6699 35.6048 9.94721 28.8623 4.1526C22.4477 -1.34877 12.3952 -1.41442 6.15111 4.12197C-0.60016 10.1135 -1.98627 19.8689 2.94163 27.1472C5.31595 30.6529 8.5298 33.1125 12.6313 34.2285C18.3375 35.7778 23.6021 34.7712 28.53 31.0336C28.8055 31.41 29.011 31.7776 29.2996 32.0752C32.5265 35.3227 35.7579 38.5614 38.998 41.7957C39.2866 42.0889 39.5795 42.3865 39.9206 42.6185C40.7907 43.2093 41.8139 43.1218 42.4611 42.3821C43.1432 41.6031 43.1694 40.7496 42.5966 39.905L42.5922 39.9006ZM17.5242 30.9461C10.0034 30.9592 3.9998 25.0071 3.98231 17.5187C3.96045 9.96909 9.9815 3.88563 17.4717 3.88126C24.8876 3.87688 30.9874 9.97784 31.0049 17.418C31.018 24.8714 24.9751 30.9417 17.5242 30.9505V30.9461Z" fill="#183D4F"/>
          </g>
          <defs>
            <clipPath id="clip0_12851_57977">
              <rect width="43" height="43" fill="white"/>
            </clipPath>
          </defs>
        </svg>
        <form action="/search" method="GET">
          <input v-model="query" name="query" placeholder="What can we help you find today?" ref="navBarSearchInput">
        </form>
        <a href="#" class="search__close" @click.prevent="searchPageActive = false">
          <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
            <circle cx="22.6006" cy="22.501" r="22.3994" fill="#E3E0D6"/>
            <rect x="30.5723" y="13.5449" width="2.17606" height="23.9366" rx="1.08803" transform="rotate(45 30.5723 13.5449)" fill="#8A8885"/>
            <rect x="32.1089" y="30.4668" width="2.17606" height="23.9366" rx="1.08803" transform="rotate(135 32.1089 30.4668)" fill="#8A8885"/>
          </svg>
        </a>
      </div>
      <div class="search__content">
        <div v-if="loading" class="search__sections">
          <div class="search__section search__section--products">
            <div class="search__section-title">Products</div>
            <div class="search__section-content search__section-content--listings">
              <div v-for="n in 9" class="search__listing search__listing--empty">
                <div class="search__listing-image"></div>
                <div class="search__empty-line"></div>
                <div class="search__empty-line"></div>
              </div>
            </div>
          </div>
          <div class="search__section search__section--searches">
            <div class="search__section">
              <div class="search__section-title">Collections</div>
              <div class="search__section-content">
                <ul>
                  <li v-for="n in 3">
                    <div class="search__collection search__collection--empty">
                      <div class="search__collection-image"></div>
                      <div class="search__empty-line"></div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="search__section">
              <div class="search__section-title">Blog Posts</div>
              <div class="search__section-content">
                <div  v-for="n in 3" class="search__post search__post--empty">
                  <div class="search__post-image"></div>
                  <div class="search__post-content">
                    <div class="search__empty-line"></div>
                    <div class="search__empty-line"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="searchedListings.length || searchedCollections.length || searchedPosts.length" class="search__sections">
          <div class="search__section search__section--products">
            <div class="search__section-title">Products</div>
            <div v-if="searchedListings.length" class="search__section-content search__section-content--listings">
              <a :href="relativeUrl(listing.url)" v-for="listing in searchedListings" :key="listing.id" class="search__listing">
                <img :src="listing.images[0].url" :alt="listing.images[0].alt" class="search__listing-image">
                <div class="search__listing-tags">
                  <ListingTags :listing="listing" :potencies="false" />
                </div>
                <div class="search__listing-title">{{ listing.title }}</div>
              </a>
            </div>
            <div v-else class="search__section-content">
              <img src="/images/no-results.png" alt="No Results" width="126" />
            </div>
          </div>
          <div class="search__section search__section--searches">
            <div class="search__section">
              <div class="search__section-title">Collections</div>
              <div class="search__section-content">
                <ul v-if="searchedCollections.length">
                  <li v-for="collection in searchedCollections.slice(0, 3)" :key="collection.id">
                    <a :href="'/c/' + collection.slug + '/'" class="search__collection">
                      <img v-if="collection.icon_image" :src="collection.icon_image.url" :alt="collection.icon_image.alt" class="search__collection-image">
                      <div class="search__collection-title">{{ collection.name }}</div>
                    </a>
                  </li>
                </ul>
                <div v-else class="search__section-content">
                  <img src="/images/no-results.png" alt="No Results" width="126" />
                </div>
              </div>
            </div>
            <div class="search__section">
              <div class="search__section-title">Blog Posts</div>
              <div v-if="searchedPosts.length" class="search__section-content">
                <a :href="`/learn/${post.slug}/`" v-for="post in searchedPosts.slice(0, 3)" :key="post.id" class="search__post">
                  <img :src="post.image.url" :alt="post.image.alt" class="search__post-image">
                  <div class="search__post-content">
                    <div class="search__post-date">{{ moment(post.created_at).format('LL') }}</div>
                    <div class="search__post-title">{{ post.title }}</div>
                  </div>
                </a>
              </div>
              <div v-else class="search__section-content">
                <img src="/images/no-results.png" alt="No Results" width="126" />
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="query" class="search__empty">
            <img src="/images/no-results.png" alt="No Results" class="search__empty-image" />
            <div class="search__empty-title">There are no search results for &quot;{{ query }}&quot;</div>
          </div>
          <div class="search__sections">
            <div class="search__section search__section--searches">
              <div class="search__section-title">Top Searches</div>
              <div class="search__section-content">
                <ul>
                  <li v-for="topSearch in topSearches" :key="topSearch">
                    <a :href="`/search/?query=${encodeURIComponent(topSearch)}`" class="search__top">
                      {{ topSearch }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="search__section search__section--products">
              <div class="search__section-title">Best Sellers</div>
              <div class="search__section-content search__section-content--listings">
                <a :href="relativeUrl(listing.url)" v-for="listing in bestSellers" :key="listing.id" class="search__listing">
                  <img :src="listing.images[0].url" :alt="listing.images[0].alt" loading="lazy" class="search__listing-image">
                  <div class="search__listing-tags">
                    <ListingTags :listing="listing" :potencies="false" />
                  </div>
                  <div class="search__listing-title">{{ listing.title }}</div>
                </a>
              </div>
            </div>
            <div class="search__sidebar">
              <div class="search__section">
                <div class="search__section-title">Recent Blog Posts</div>
                <div class="search__section-content">
                  <a :href="`/learn/${post.slug}/`" v-for="post in latestPosts" :key="post.id" class="search__post">
                    <img v-if="post.image" :src="post.image.url" :alt="post.image.alt" loading="lazy" class="search__post-image">
                    <div class="search__post-content">
                      <div class="search__post-date">{{ moment(post.created_at).format('LL') }}</div>
                      <div class="search__post-title">{{ post.title }}</div>
                    </div>
                  </a>
                </div>
              </div>
              <div class="search__section">
                <div class="search__section-title">Resources</div>
                <div class="search__section-content">
                  <ul>
                    <li><a href="/pages/shipping-policy/" class="search__top">Shipping Policy</a></li>
                    <li><a href="/pages/coas/" class="search__top">Lab Results</a></li>
                    <li><a href="/pages/contact-us/" class="search__top">Contact Us</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.search {
  .search__nav-bar {
    display: flex;

    input {
      display: none;
      color: var(--Teal, #3B717B);
      font-family: Outfit, 'Outfit Fallback', Arial, serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px; /* 166.667% */
      border-radius: 8px;
      border: 1px solid #F7F6F2;
      background: #FFF;
      padding: 6px 16px;

      @media screen and (min-width: 865px) {
        display: flex;
      }

      &:hover {
        background: var(--Neutral-100, #F1EFE8);
      }
    }

    @media screen and (min-width: 865px) {
      position: relative;

      svg {
        position: absolute;
        top: 6px;
        right: 16px;
        width: 22px;
        height: 22px;
      }
    }
  }

  .search__page {
    display: none;
    background: var(--Neutral-50, #F7F6F2);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999999999;
    overflow: auto;

    &.search__page--active {
      display: block;
    }

    .search__bar {
      position: fixed;
      width: 100%;
      padding: 20px;
      background: #F1EFE8;
      display: flex;
      align-items: center;
      gap: 8px;

      @media screen and (min-width: 865px) {
        padding: 40px 100px;
        gap: 24px;
      }

      form {
        flex: 1;

        input {
          width: 100%;
          color: #9D9B95;
          font-family: Outfit, 'Outfit Fallback', Arial, serif;
          font-size: 16px;
          font-weight: 600;
          line-height: 20px; /* 142.857% */
          background: #F1EFE8;
          border: 0;
          outline: none;

          @media screen and (min-width: 865px) {
            font-size: 28px;
            line-height: 52.5px; /* 187.5% */
          }

          &::placeholder {
            color: #C5C0B2;
          }
        }
      }

      .search__magnifying {
        width: 22px;
        height: 22px;

        @media screen and (min-width: 865px) {
          width: 43px;
          height: 43px;
        }
      }

      .search__close {
        svg {
          width: 32.799px;
          height: 32.799px;

          @media screen and (min-width: 865px) {
            width: 44.799px;
            height: 44.799px;
          }

          &:hover circle {
            fill: var(--Neutral-400, #D5D2C5);
          }
        }
      }
    }

    .search__content {
      padding: 20px 16px;
      margin-top: 73px;

      @media screen and (min-width: 865px) {
        padding: 60px 100px 100px 100px;
        margin-top: 135px;
      }
    }
  }
}
</style>
